export enum Action {
  Ui = "https://seed.sqcc.gov.np",
  // Base = "https://seedapi.sqcc.gov.np",
  // Base = "https://sqccapi.pathway.com.np",
  // Base = "http://127.0.0.1:8000",
  API = "api",
  User = "auth/user/userdetail",
  CustomUser = "auth/user/customuser",
  GeneralUsers = "auth/user/generaluserlist",
  AdminUsers = "auth/user/highleveluserlist",
  ConsumerCompany = "auth/user/consumercompanylist",
  SeedCompany = "auth/user/seedcompanylist",
  RegisterCompany = "auth/user/registercompany",
  EditCompanyProfile = "auth/user/editprofile",
  ApproveCompany = "auth/user/approvecompany",
  RejectCompany = "auth/user/rejectcompany",
  Role = "role",
  UserInRole = "userinrole",

  Province = "auth/user/province",
  District = "auth/user/district",
  Palika = "auth/user/palika",
  BankList = "auth/user/banklist",

  Register = "auth/user/register",
  Activate = "auth/user/activate",
  ChangeUsername = "auth/user/auth_users/set_username",
  ChangePassword = "auth/user/auth_users/set_password",
  ForgotPassword = "auth/user/forgotpassword",
  ResetPassword = "auth/user/auth_users/reset_password_confirm",
  CheckAccess = "auth/user/accesscheck",
  ObtainToken = "auth/user/obtaintoken",
  RefreshToken = "auth/user/refreshtoken",
  VerifyToken = "auth/user/verifytoken",
  SocialAuth = "auth/user/socialauth",
  ChangeProfilePicture = "auth/user/changepp",
  UserExists = "auth/user/userexists",

  CropName = "crop",
  Crops = "crops",
  CropCategory = "cropcategory",
  CropVarietiesRangeFiltered = "cropvarietiesrangefiltered",
  CropType = "croptype",
  Images = "image",
  CropVarietiesMetaVar = "cropvarietiesmetavar",
  CropvVarietyForAdmin = "cropvarietyforadmin",
  CropVarieties = "cropvarieties",
  CropVarietiesRetrieve = "cropvarieties/<id>",
  CompareCrops = "comparecropvariety",
  CropVarietiesFilterable = "searchablevar",
  CropVariable = "cropvarietiesvariables",
  CropVarietiesValues = "cropvarietiesvalues",
  Image = "image",
  CropImage = "cropimage",
  SimilarCropVariety = "similarcropvarieties",
  CropCategoryNavBar = "cropcategorynavbar",
  SortableVariables = "sortablevariables",
  Sort = "sort",
  CropVarietyComment = "comment",
  AllComments = "all_comments",
  CommentSeenStatus = "comment_seen_status",
  RecentCropVarieties = "recentvarieties",
  DSADGroup = "dsadgroup",
  CropVarietyReply = "reply",
  SupplierCropVariety = "supplierbycropvariety",
  CropVarietiesDetail = "cropvarietiesdetail",
  ExportPdf = "exportpdf",
  CropVarietiesDocs = "cropvarieties/<id>/docs",

  Preferences = "preferences",
  SeedType = "seedtype",
  SeedClass = "seedclass",
  Demand = "demand",
  DemandConfirm = "demandconfirm",
  DSADCrop = "dsadcrop",
  Supply = "supply",
  SupplyConfirm = "supplyconfirm",
  Supplier = "supplier",
  Cluster = "cluster",
  Allocate = "allocate",
  ExcelReportDistribution = "excelreportdistribution",
  AdminAllocation = "adminallocation",
  UserAllocation = "userallocation",
  CurrentMarketStat = "currentmarketstat",
  SupplierStat = "supplierstat",
  SupplierInfo = "auth/user/supplierinfo",
  Notices = "notice",
  AdminSupply = "adminsupply",
  AdminTotalSupplyQuantityAPI = "admin_supply_total_quantity",
  AdminTotalDemandQuantityAPI = "admin_demand_total_quantity",
  AdminDemand = "admindemand",
  FiscalYear = "fiscalyear",
  Distribution = "distribution",
  BalanceSheetReport = "exportreport",
  UnallocatedReport = "exportunallocatedreport",
  CategoryReport = "categoryreport",
  AdHocReport = "adhocreport",
  DemandForecastReport = "demandforecastreport",
  UnsoldAllocationReport = "unsoldallocationreport",
  UnsoldAllocationReportDetail = "unsoldallocationreportdetail",
  UnsoldAllocationDemandDetail = "unsoldallocationdemanddetail",
  CropCarousel = "cropcarousel",
  CropVariableSetting = "cropvariablesetting",
  SendMail = "sendmaillog",
  SendMailLogGroup = "sendmailloggroup",
  PreRenderCropVariableDetail = "prerender",
  Dashboard = "dashboard",
  SupplyDemandTrend = "trend",
  CropIdBySlug = "cropidbyslug",
  CropVarietyIdBySlug = "varietyidbyslug",
  PostSupplyReport = "postsupplyreport",
  PostDemandReport = "postdemandreport"
}
